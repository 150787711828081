// Chakra imports
import { Box, Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/auth";
import { AuthContextBd } from "../../../contexts/bdFire";
// Custom components
import Card from "components/card/Card.js";
import Banner from "views/admin/blueportal/components/Banner";
import TableTopCreators from "views/admin/blueportal/components/TableTopCreators";
// Assets
import { tableColumnsTopCreators } from "views/admin/blueportal/variables/tableColumnsTopCreators";
import tableDataTopCreators from "views/admin/blueportal/variables/tableDataTopCreators.json";
export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const { user } = useContext(AuthContext);
  const {
    compareItem,
    registrarAtualizacao,
    handleAddLote,
    handleAddLog,
    handleAddNormalizacao,
  } = useContext(AuthContextBd);
  const [loadingAuthUser, setLoadingAuthUser] = useState(true);

  const [escolhaClientes, setEscolhaClientes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [epcValues, setEpcValues] = useState([]);
  const [fetching, setFetching] = useState(false);
  const toastId = useRef(null);
  const [dataHora, setDataHora] = useState([]);

  async function fetchDataGet() {
    let startTimestamp = dataHora[0].getTime();
    let endTimestamp = dataHora[1].getTime();
    await handleAddNormalizacao(
      startTimestamp,
      endTimestamp,
      escolhaClientes,
      user.uid
    );
    toastId.current = toast("Preparando para salvar.", {
      position: "top-center",
      type: toast.TYPE.INFO,
      autoClose: 4000,
    });
    try {
      const url = `https://cristal-tdc-antena1.firebaseio.com/crystal.json`;
      const response = await fetch(url);
      const data = await response.json();
      let epcCounts = {};
      for (const key in data) {
        const item = data[key];
        for (let i = 0; i < item.length; i++) {
          let reading_created_at = item[i].reading_created_at;
          let reading_epc_hex = item[i].reading_epc_hex;
          let itemTimestamp = new Date(reading_created_at).getTime();
          if (
            itemTimestamp >= startTimestamp &&
            itemTimestamp <= endTimestamp
          ) {
            if (epcCounts[reading_epc_hex]) {
              epcCounts[reading_epc_hex].count++;
            } else {
              epcCounts[reading_epc_hex] = {
                created_at: reading_created_at,
              };
            }
          }
        }
      }
      let epcValues2 = Object.entries(epcCounts).map(
        ([value, { created_at }]) => ({
          value,
          created_at,
        })
      );
      return setEpcValues(epcValues2), await handleAddProduto(epcValues2);
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  }

  async function startFetching() {
    toastId.current = toast("Coletando BlueTags!", {
      position: "top-center",
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
    });

    setDataHora([new Date(), ...dataHora]);
    await handleAddLog("inicioColeta", user.uid);
  }

  async function stopFetching() {
    await new Promise((resolve) => setTimeout(resolve, 8000));
    await handleAddLog("fimColeta", user.uid);
    setDataHora([...dataHora, new Date()]);
    toast.update(toastId.current, {
      render: "Conexão Fechada",
      type: toast.TYPE.INFO,
      autoClose: 2000,
    });
  }
  useEffect(() => {
    loadItems();
  }, []);

  async function loadItems() {
    const comparacaoItem2 = await compareItem(
      "distribuidor",
      "status",
      "==",
      true
    );
    comparacaoItem2.forEach((item) => {
      return setClientes((clientes) => [
        { label: item.identificador + " - " + item.nome, value: item.id },
        ...clientes,
      ]);
    });
  }

  async function handleAddProduto(epcValues2) {
    if (escolhaClientes.length === 0) {
      toastId.current = toast("Escolher distribuidor.", {
        position: "top-center",
        type: toast.TYPE.ERROR,
        autoClose: 4000,
      });
      setFetching(true);
      return;
    }
    if (epcValues2.length === 0) {
      toastId.current = toast("Não há Bluetags para associar.", {
        position: "top-center",
        type: toast.TYPE.ERROR,
        autoClose: 4000,
      });
      setFetching(true);
      return;
    }
    async function logSalvar() {
      await handleAddLote(escolhaClientes, epcValues2, user.uid);
    }

    async function comparePromises() {
      await epcValues2.map(async (item) => {
        await registrarAtualizacao(item.value, escolhaClientes, user.uid);
      });
    }

    try {
      await logSalvar();
      await comparePromises();
      toastId.current = toast("BlueTags salvas com sucesso!", {
        position: "top-center",
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      });
      setEpcValues([]);
      setFetching(true);
      setDataHora([]);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
        >
          <Banner
            clientes={clientes}
            escolhaClientes={(item) => setEscolhaClientes(item)}
            startFetching={startFetching}
            stopFetching={stopFetching}
            fetchDataGetUser={fetchDataGet}
            fetching={fetching}
          />
        </Flex>
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          <Card px="0px" mb="20px">
            <TableTopCreators
              blueTags={epcValues}
              tableData={tableDataTopCreators}
              columnsData={tableColumnsTopCreators}
            />
          </Card>
        </Flex>
      </Grid>
    </Box>
  );
}
