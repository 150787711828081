import 'firebase/compat/firestore';
import firebase from "../services/firebaseConnection";

const db = firebase.firestore();
db.enablePersistence().catch((err) => {
  if (err.code === "failed-precondition") {
    console.log(
      "Multiple tabs open."
    );
  } else if (err.code === "unimplemented") {
    console.log(
      "The current browser does not support all of the features required to enable persistence."
    );
  }
});
