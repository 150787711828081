import { Route, Routes } from 'react-router-dom';
import AdminLayout from '../layouts/admin';
import BluePortal from '../views/admin/blueportal';
import DataTables from '../views/admin/dataTables';
import Dashboard from '../views/admin/default';
import NFT from '../views/admin/marketplace';
import Profile from '../views/admin/profile';
import SignIn from '../views/auth/signIn';
import ProtectedRoutes from "./ProtectedRoutes";



export default function Router() {
  return (
    <Routes>
       <Route path="/" element={<SignIn />} />
      <Route  element={<ProtectedRoutes />}>
        <Route path={`/admin`} element={<AdminLayout />}>
          <Route index path={`dashboard`} element={<Dashboard />} />
          <Route path={`blockchain`} element={<NFT />} />
          <Route path={`blueportal`} element={<BluePortal />} />
          <Route path={`garrafoes`} element={<DataTables />} />
          <Route path={`profile`} element={<Profile />} />
        </Route>
      </Route> 
    </Routes>
  );
}
