import { createContext, useState } from "react";
import firebase from "../services/firebaseConnection";
import { useUniqueId } from "../contexts/uuid";

export const AuthContextBd = createContext({});

function AuthProvider({ children }) {
  const [loadingAuth, setLoadingAuth] = useState(true);
  let dadosLoad = [];
  const uniqueId = useUniqueId();

  ////
  async function compareItem(item, itemCompare, to, itemTOCompare) {
    if (!item || !itemCompare || !to || !itemTOCompare) {
      return Promise.resolve(false);
    }
    const itemsQuery = firebase
      .firestore()
      .collection(item)
      .where(itemCompare, to, itemTOCompare);
    return itemsQuery.get().then((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      if (results.length !== 0) {
        return results;
      } else {
        return false;
      }
    });
  }

  async function handleAdd(doc, item, type, itensAdd) {
    if (!doc && type === "add") {
      return await firebase
        .firestore()
        .collection(item)
        .add(itensAdd)
        .then((docRef) => {
          return docRef;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (doc && type === "update") {
      return await firebase
        .firestore()
        .collection(item)
        .doc(doc)
        .update(itensAdd)
        .then(() => {
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (doc && type === "add") {
      return await firebase
        .firestore()
        .collection(item)
        .doc(doc)
        .set(itensAdd)
        .then(() => {
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return;
  }

  function registrarAtualizacao(pfc, cliente, user) {
    return new Promise((resolve, reject) => {
      const produtoRef = firebase
        .firestore()
        .collection("produto")
        .where("pfc", "==", pfc)
        .where("rejeitado", "==", false);

      produtoRef
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            const docRef = snapshot.docs[0].ref;
            const produtoData = snapshot.docs[0].data();
            const distribuidorAntigo = produtoData.distribuidorAtual;

            // Usando o método FieldValue.increment para incrementar a quantidadeDevolucoes
            const updatePromise = docRef.update({
              quantidadeDevolucoes: firebase.firestore.FieldValue.increment(1),
              ultimoEnvasse: new Date(),
              distribuidorAtual: cliente,
              distribuidorAntigo: distribuidorAntigo,
              user: user
            });

            // Adicionando o documento na coleção "envase"
            const envaseData = {
              pfc: pfc,
              created: new Date(),
              distribuidorAtual: cliente,
              distribuidorAntigo: distribuidorAntigo,
              user: user
            };
            const addEnvasePromise = firebase
              .firestore()
              .collection("envase")
              .add(envaseData);

            return Promise.all([updatePromise, addEnvasePromise]);
          } else {
            console.log("Produto não encontrado.");
            return null;
          }
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.error("Erro ao registrar a atualização:", error);
          reject(error);
        });
    });
  }

  function registrarAtualizacaoArquivo(pfc, cliente, lote) {
    return new Promise((resolve, reject) => {
      const produtoRef = firebase
        .firestore()
        .collection("produto")
        .where("pfc", "==", pfc)
        .where("rejeitado", "==", false)
        .limit(1);

      produtoRef
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            const docRef = snapshot.docs[0].ref;
            return docRef.update({
              quantidadeDevolucoes: 0,
              ultimoEnvasse: new Date(),
              distribuidorAtual: cliente,
              distribuidorAntigo: "Sem histórico",
              lote: lote,
            });
          } else {
            console.log("Produto não encontrado.");
            return null;
          }
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.error("Erro ao registrar a atualização:", error);
          reject(error);
        });
    });
  }

  async function getDevolucoesQuantidadeData(date) {
    try {
      const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

      const produtoRef = firebase
        .firestore()
        .collection("envase")
        .where("created", ">=", startOfDay)
        .where("created", ">=", startOfDay)
        .where("created", "<=", endOfDay);

      const snapshot = await produtoRef.get();
      if (!snapshot.empty) {
        return snapshot.size;
      }
      return 0;
    } catch (error) {
      console.error("Erro ao buscar as devoluções:", error);
      return null;
    }
  }

  async function getDevolucoesQuantidade() {
    try {
      const produtoRef = firebase
        .firestore()
        .collection("produto")
        .where("distribuidorAtual", "!=", "Sem histórico");
      const snapshot = await produtoRef.get();
      if (!snapshot.empty) {
        return snapshot.size;
      }
    } catch (error) {
      console.error("Erro ao registrar a atualização:", error);
      return null;
    }
  }

  async function buscarDadosPeriodo(clienteId, dataInicio, dataFim) {
    const loteProdutoRef = firebase.firestore().collection("loteProduto");
    const devolucoesRef = firebase.firestore().collection("devolucoes");
    const clientesRef = firebase.firestore().collection("clientes");

    const loteProdutoQuery = loteProdutoRef
      .where("cliente", "==", clienteId)
      .where("created", ">=", dataInicio)
      .where("created", "<=", dataFim);
    const loteProdutoSnapshot = await loteProdutoQuery.get();

    let produtos = [];
    let quantidadeLote = 0;
    let quantidadeProdutos = 0;

    loteProdutoSnapshot.docs.forEach((loteProdutoDoc) => {
      const loteProduto = loteProdutoDoc.data();
      produtos.push(loteProduto.produtos);
      quantidadeProdutos += loteProduto.produtos.length;
      quantidadeLote++;
    });

    const devolucoesQuery = devolucoesRef
      .where("cliente", "==", clienteId)
      .where("dataDevolucao", ">=", dataInicio)
      .where("dataDevolucao", "<=", dataFim);
    const devolucoesSnapshot = await devolucoesQuery.get();

    let devolucoesCliente = [];
    let devolucoesClienteDevolucao = [];
    let devolucoesClienteNome = [];

    let quantidadeDevolucoesCliente = 0;
    let quantidadeDevolucoesClienteDevolucao = 0;

    devolucoesSnapshot.docs.forEach((devolucaoDoc) => {
      const devolucao = devolucaoDoc.data();
      const { cliente, clinteDevolucao } = devolucao;

      if (cliente === clienteId) {
        quantidadeDevolucoesCliente++;
        devolucoesCliente.push(devolucao);
      }

      if (clinteDevolucao !== clienteId) {
        quantidadeDevolucoesClienteDevolucao++;
        devolucoesClienteNome.push(devolucao.clinteDevolucao);
        devolucoesClienteDevolucao.push(devolucao);
      }
    });

    const clienteDoc = await clientesRef.doc(clienteId).get();
    const cliente = clienteDoc.exists ? clienteDoc.data() : null;

    return {
      produtosQuantidade: quantidadeProdutos,
      devolucoesClienteQuantidade: quantidadeDevolucoesCliente,
      devolucoesClienteDevolucaoQuantidade:
        quantidadeDevolucoesClienteDevolucao,
      cliente: cliente,
      clienteDevolucao: devolucoesClienteNome,
      produtos: produtos,
      devolucoesCliente: devolucoesCliente,
      devolucoesClienteDevolucao: devolucoesClienteDevolucao,
      lote: quantidadeLote,
    };
  }

  async function getEnvasamentosPorProduto() {
    try {
      // Obter o primeiro e o último dia do mês atual
      const now = new Date();
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);

      // Consultar todos os envasamentos do mês atual
      const envaseRef = firebase
        .firestore()
        .collection("envase")
        .where("created", ">=", firstDayOfMonth)
        .where("created", "<=", lastDayOfMonth);

      const snapshot = await envaseRef.get();

      // Contar o número de envasamentos por produto
      const countByProduct = {};
      snapshot.forEach(doc => {
        const produtoId = doc.data().pfc;
        countByProduct[produtoId] = (countByProduct[produtoId] || 0) + 1;
      });

      // Organizar os produtos por número de envasamentos
      const productsByEnvaseCount = {
        '3+': [],
        '2': [],
        '1': [],
        '0': [],
      };
      for (const [produtoId, count] of Object.entries(countByProduct)) {
        if (count >= 3) {
          productsByEnvaseCount['3+'].push(produtoId);
        } else if (count == 2) {
          productsByEnvaseCount['2'].push(produtoId);
        } else {
          productsByEnvaseCount[count].push(produtoId);
        }
      }

      // Consultar todos os produtos
      const produtoRef = firebase.firestore().collection("produto");
      const produtoSnapshot = await produtoRef.where("distribuidorAtual", "!=", "Sem histórico").get();


      // Obter o tamanho da coleção de produtos
      const totalProdutos = produtoSnapshot.size;

      // Obter o número de produtos envasados
      const totalEnvasados = productsByEnvaseCount['1'].length + productsByEnvaseCount['2'].length + productsByEnvaseCount['3+'].length;

      // Calcular o número de produtos que não foram envasados
      const totalNaoEnvasados = totalProdutos - totalEnvasados;

      // Adicionar esse número à lista de produtos com 0 envasamentos
      productsByEnvaseCount['0'].length = totalNaoEnvasados;

      return productsByEnvaseCount;
    } catch (error) {
      console.error("Erro ao buscar os envasamentos:", error);
      return null;
    }
  }

  async function loadCollectionSize(item) {
    try {
      const querySnapshot = await firebase.firestore().collection(item).get();
      return querySnapshot.size;
    } catch (error) {
      console.log("Deu algum erro: ", error);
      return 0; // Return a default value or handle the error accordingly.
    }
  }

  async function getDataForChart() {
    // Coleção do Firestore
    const produtosRef = firebase.firestore().collection("produto");

    // Inicializa os dados do gráfico
    const chartData = [];

    // Obtém todos os produtos com distribuidorAtual diferente de "Sem historico"
    const allProductsSnapshot = await produtosRef.where("distribuidorAtual", "!=", "Sem histórico").get();

    const allProducts = [];
    allProductsSnapshot.forEach(doc => {
      allProducts.push({
        id: doc.id,
        data: doc.data(),
      });
    });

    for (let i = 0; i < 6; i++) {
      // Define o intervalo de tempo para cada mês
      const monthStart = new Date(new Date().getFullYear(), new Date().getMonth() - i, 1);
      const monthEnd = new Date(new Date().getFullYear(), new Date().getMonth() - i + 1, 0);

      // Filtra os produtos envasados no mês especificado
      const monthlyProducts = allProducts.filter(product => {
        if (product.data.ultimoEnvasse) {
          const ultimoEnvasse = product.data.ultimoEnvasse.toDate();  // converte o timestamp do Firestore para um objeto Date
          return ultimoEnvasse >= monthStart && ultimoEnvasse <= monthEnd;
        }
        return false;
      });

      // Adiciona a contagem de produtos ao array chartData
      chartData.push({
        count: monthlyProducts.length, // Aqui adiciona a contagem de produtos
        month: i // Aqui adiciona o mês
      });
    }

    return chartData;
  }

  async function getDataForChartEnvase() {
    // Coleção do Firestore
    const produtosRef = firebase.firestore().collection("envase");

    // Inicializa os dados do gráfico
    const chartData = [];

    // Obtém todos os produtos com distribuidorAtual diferente de "Sem historico"
    const allProductsSnapshot = await produtosRef.get();

    const allProducts = [];
    allProductsSnapshot.forEach(doc => {
      allProducts.push({
        id: doc.id,
        data: doc.data(),
      });
    });

    for (let i = 0; i < 6; i++) {
      // Define o intervalo de tempo para cada mês
      const monthStart = new Date(new Date().getFullYear(), new Date().getMonth() - i, 1);
      const monthEnd = new Date(new Date().getFullYear(), new Date().getMonth() - i + 1, 0);

      // Filtra os produtos envasados no mês especificado
      const monthlyProducts = allProducts.filter(product => {
        if (product.data.created) {
          const ultimoEnvasse = product.data.created.toDate();  // converte o timestamp do Firestore para um objeto Date
          return ultimoEnvasse >= monthStart && ultimoEnvasse <= monthEnd;
        }
        return false;
      });

      // Adiciona a contagem de produtos ao array chartData
      chartData.push({
        count: monthlyProducts.length, // Aqui adiciona a contagem de produtos
        month: i // Aqui adiciona o mês
      });
    }

    return chartData;
  }

  async function handleAddLog(tipo, user) {
    await firebase
      .firestore()
      .collection("log")
      .add({
        user: user,
        update: tipo,
        created: new Date(),
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleAddLote(distribuidor, pfc, user) {
    await firebase
      .firestore()
      .collection("lote")
      .add({
        user: user,
        distribuidor: distribuidor,
        pfc: pfc,
        created: new Date(),
      })
      .then(() => {
        return
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleAddNormalizacao(startTimestamp, endTimestamp, escolhaClientes, user) {
    await firebase
      .firestore()
      .collection("normalizacao")
      .add({
        user: user,
        dataInicio: startTimestamp,
        dataFim: endTimestamp,
        distribuidor: escolhaClientes,
        created: new Date(),
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
  }
  /////

  function loadItem(item, itemId) {
    return firebase
      .firestore()
      .collection(item)
      .doc(itemId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const results = [];
          results.push({ id: doc.id, ...doc.data() });
          return results;
        } else {
          console.log("Documento não encontrado.");
        }
      })
      .catch((err) => {
        console.log("Deu algum erro: ", err);
      });
  }

  function loadCollection(item) {
    return firebase
      .firestore()
      .collection(item)
      .get()
      .then((doc) => {
        if (doc) {
          const results = [];
          doc.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() });
          });
          return results;
        } else {
          console.log("Documento não encontrado.");
        }
      })
      .catch((err) => {
        console.log("Deu algum erro: ", err);
      });
  }

  function handleExc(doc, item) {
    setLoadingAuth(false);
    if (doc) {
      return firebase
        .firestore()
        .collection(item)
        .doc(doc)
        .delete()
        .then(() => {
          return setLoadingAuth(true);
        })
        .catch((error) => {
          console.log(error);
          return setLoadingAuth(true);
        });
    }
    return;
  }


  // async function compareItem(item, itemCompare, to, itemTOCompare) {
  //   if (!item || !itemCompare || !to || !itemTOCompare) {
  //     return Promise.resolve(false);
  //   }
  //   const itemsQuery = firebase
  //     .firestore()
  //     .collection(item)
  //     .where(itemCompare, to, itemTOCompare);
  //   return itemsQuery.get().then((querySnapshot) => {
  //     const results = [];
  //     querySnapshot.forEach((doc) => {
  //       results.push({ id: doc.id, ...doc.data() });
  //     });
  //     if (results.length !== 0) {
  //       return results;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  function compareItens(
    item,
    nomeToast,
    itemCompare,
    to,
    itemTOCompare,
    itemCompare2,
    to2,
    itemTOCompare2
  ) {
    if (!item || !nomeToast || !itemCompare || !itemTOCompare) {
      return;
    }
    const itemsQuery = firebase
      .firestore()
      .collection(item)
      .where(itemCompare, to, itemTOCompare)
      .where(itemCompare2, to2, itemTOCompare2);
    return itemsQuery.get().then((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      if (results.length !== 0) {
        return results;
      } else {
        return false;
      }
    });
  }

  async function compareItensSub2Where(
    item,
    itemCompare,
    to,
    itemTOCompare,
    itemCompare2,
    to2,
    itemTOCompore2,
    itemSub,
    itemCompare3,
    to3
  ) {
    if (!item || !itemCompare || !itemTOCompare) {
      return Promise.resolve([]);
    }
    const results = [];
    const itemsQuery = firebase
      .firestore()
      .collectionGroup(item)
      .where(itemCompare, to, itemTOCompare)
      .where(itemCompare2, to2, itemTOCompore2);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const quantidadeItens = querySnapshotTable2.size;
          results.push({
            id: doc.id,
            ...doc.data(),
            produtos: quantidadeItens,
          });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
    }
    return results;
  }

  async function compareItensSub2WhereProduto(
    item,
    itemCompare,
    to,
    itemTOCompare,
    itemCompare2,
    to2,
    itemTOCompore2,
    itemSub,
    itemCompare3,
    to3
  ) {
    if (!item || !itemCompare || !itemTOCompare) {
      return Promise.resolve([]);
    }
    const results = [];
    const itemsQuery = firebase
      .firestore()
      .collectionGroup(item)
      .where(itemCompare, to, itemTOCompare)
      .where(itemCompare2, to2, itemTOCompore2);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const idTabelaCliente = doc.data().cliente;
        const clienteP = firebase
          .firestore()
          .collection("clientes")
          .doc(idTabelaCliente);
        const promise2 = clienteP.get().then((querySnapshotTable2) => {
          const cliente = querySnapshotTable2.data().CNPJ;
          const nome = querySnapshotTable2.data().nome;
          return cliente, nome;
        });
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const produtos = querySnapshotTable2.docs.map((doc) => [
            doc.data().produtos,
          ]);
          const quantidadeProdutos = produtos[0][0].length;
          return {
            id: doc.id,
            ...doc.data(),
            produtos: quantidadeProdutos,
          };
        });
        promises.push(promise, promise2);
      });
      const resolvedPromises = await Promise.all(promises);
      resolvedPromises.forEach((resolvedPromise, index) => {
        if (index % 2 === 0) {
          resolvedPromise.cliente = resolvedPromises[index + 1]; // atribui o CNPJ do cliente
          results.push(resolvedPromise);
        }
      });
    }
    return results;
  }

  async function compareItensSub1Where(
    item,
    itemCompare,
    to,
    itemTOCompare,
    itemSub,
    itemCompare3,
    to3
  ) {
    if (!item || !itemCompare || !itemTOCompare) {
      return Promise.resolve([]);
    }
    const results = [];
    const itemsQuery = firebase
      .firestore()
      .collectionGroup(item)
      .where(itemCompare, to, itemTOCompare);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const quantidadeItens = querySnapshotTable2.size;
          results.push({
            id: doc.id,
            ...doc.data(),
            produtos: quantidadeItens,
          });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
    }
    return results;
  }

  async function compareItensSub1WhereProduto(
    item,
    itemCompare,
    to,
    itemTOCompare,
    itemSub,
    itemCompare3,
    to3
  ) {
    if (!item || !itemCompare || !itemTOCompare) {
      return Promise.resolve([]);
    }

    const results = [];
    const itemsQuery = firebase
      .firestore()
      .collectionGroup(item)
      .where(itemCompare, to, itemTOCompare);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const idTabelaCliente = doc.data().cliente;
        const clienteP = firebase
          .firestore()
          .collection("clientes")
          .doc(idTabelaCliente);
        const promise2 = clienteP.get().then((querySnapshotTable2) => {
          const cliente = querySnapshotTable2.data().CNPJ;
          const nome = querySnapshotTable2.data().nome;
          return cliente, nome;
        });
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const produtos = querySnapshotTable2.docs.map((doc) => [
            doc.data().produtos,
          ]);
          const quantidadeProdutos = produtos[0][0].length;

          return {
            id: doc.id,
            ...doc.data(),
            produtos: quantidadeProdutos,
          };
        });
        promises.push(promise, promise2);
      });
      const resolvedPromises = await Promise.all(promises);
      resolvedPromises.forEach((resolvedPromise, index) => {
        if (index % 2 === 0) {
          resolvedPromise.cliente = resolvedPromises[index + 1]; // atribui o CNPJ do cliente
          results.push(resolvedPromise);
        }
      });
    }
    return results;
  }

  async function compareItensSub1WhereDoc(
    item,
    itemCompare,
    to,
    itemTOCompare,
    itemSub,
    itemCompare3,
    to3
  ) {
    const results = [];
    const itemsQuery = firebase
      .firestore()
      .collectionGroup(item)
      .where(itemCompare, to, itemTOCompare);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const produtos = querySnapshotTable2.docs.map((doc) => [
            { id: doc.id, ...doc.data() },
          ]);
          results.push({ id: doc.id, ...doc.data(), produtos });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
    }
    return results;
  }

  async function compareItensSub1WhereDocRemessa(
    item,
    itemCompare,
    to,
    itemTOCompare,
    itemSub,
    itemCompare3,
    to3
  ) {
    const results = [];
    const itemsQuery = firebase
      .firestore()
      .collectionGroup(item)
      .where(itemCompare, to, itemTOCompare);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const produtos = querySnapshotTable2.docs.map((doc) => [
            { id: doc.id, ...doc.data() },
          ]);
          results.push({ id: doc.id, ...doc.data(), produtos });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
    }
    return results;
  }

  async function compareItensSub1Doc(item, itemSub, itemCompare3, to3) {
    const results = [];
    const itemsQuery = firebase.firestore().collectionGroup(item);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const produtos = querySnapshotTable2.docs.map((doc) => [
            { id: doc.id, ...doc.data() },
          ]);
          results.push({ id: doc.id, ...doc.data(), produtos });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
    }
    return results;
  }

  async function compareItensSub1DocDashboard() {
    setLoadingAuth(false);
    try {
      let totalProdutos = 0;
      const itemsQuery = firebase.firestore().collectionGroup("loteProduto");
      let querySnapshot = await itemsQuery.get();
      if (!querySnapshot.empty) {
        const promises = [];
        querySnapshot.forEach((doc) => {
          const produtos = doc.data().produtos;
          if (produtos) {
            let quantidadeItens = 0; // inicializa com zero
            const produtosAtualizados = produtos.map((produto) => {
              if (produto.status !== "devolvido") {
                quantidadeItens++; // incrementa a quantidade de produtos diferentes de devolvido
              }
              return produto;
            });
            totalProdutos += quantidadeItens; // adiciona a quantidade de produtos atualizados ao total
            const loteRef = doc.ref.parent;
            const loteId = doc.id;
            // Atualiza a coleção "loteProduto" com os produtos atualizados
            const promise = loteRef
              .doc(loteId)
              .update({
                produtos: produtosAtualizados,
              })
              .then(() => true);
            promises.push(promise);
          }
        });
        await Promise.all(promises);
      }
      setLoadingAuth(true);
      return totalProdutos; // retorna o total de produtos diferentes de devolvido
    } catch (error) {
      setLoadingAuth(true);
      console.log("Erro ao registrar devolução:", error);
      return null;
    }
  }

  async function compareItensSub1DocRemessa(item, itemSub, itemCompare3, to3) {
    const results = [];
    const itemsQuery = firebase.firestore().collectionGroup(item);
    let querySnapshot = await itemsQuery.get();
    if (!querySnapshot.empty) {
      const promises = [];
      querySnapshot.forEach((doc) => {
        const idTabela = doc.data()[itemCompare3];
        const itemsQuerySub = firebase
          .firestore()
          .collectionGroup(itemSub)
          .where(itemCompare3, to3, idTabela);
        const promise = itemsQuerySub.get().then((querySnapshotTable2) => {
          const lotes = querySnapshotTable2.docs.map((doc) => [
            { id: doc.id, ...doc.data() },
          ]);
          results.push({ id: doc.id, ...doc.data(), lotes });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
    }
    return results;
  }

  // async function handleAdd(doc, item, type, itensAdd) {
  //   if (!doc && type === "add") {
  //     return await firebase
  //       .firestore()
  //       .collection(item)
  //       .add(itensAdd)
  //       .then((docRef) => {
  //         return docRef;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   if (doc && type === "update") {
  //     return await firebase
  //       .firestore()
  //       .collection(item)
  //       .doc(doc)
  //       .update(itensAdd)
  //       .then(() => {
  //         return;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   if (doc && type === "add") {
  //     return await firebase
  //       .firestore()
  //       .collection(item)
  //       .doc(doc)
  //       .set(itensAdd)
  //       .then(() => {
  //         return;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   return;
  // }

  /// DEVOLUÇÃO

  async function compareItensSub1WhereDevolucao(itemToCompare) {
    setLoadingAuth(false);
    try {
      const itemsQuery = firebase.firestore().collectionGroup("loteProduto");
      let querySnapshot = await itemsQuery.get();
      if (!querySnapshot.empty) {
        const promises = [];
        let updated = false; // variável de controle
        querySnapshot.forEach((doc) => {
          const produtos = doc.data().produtos;
          const lote = doc.data().lote;
          const cliente = doc.data().cliente;
          if (produtos) {
            const produtosAtualizados = produtos.map((produto) => {
              if (
                produto.pfc === itemToCompare &&
                produto.status !== "devolvido"
              ) {
                produto.status = "devolvido";
                // executa registrarDevolucao apenas na primeira atualização de status
                if (!updated) {
                  updated = true;
                  registrarDevolucao(
                    itemToCompare,
                    "Devolução do produto",
                    cliente,
                    lote
                  );
                }
              }
              return produto;
            });
            const loteRef = doc.ref.parent;
            const loteId = doc.id;
            // Atualiza a coleção "loteProduto" com os produtos atualizados
            const promise = loteRef
              .doc(loteId)
              .update({
                produtos: produtosAtualizados,
              })
              .then(() => true);
            promises.push(promise);
          }
        });
        const results = await Promise.all(promises);
        setLoadingAuth(true);
        return updated ? true : false; // retorna true apenas se houve atualização
      }
      setLoadingAuth(true);
      return false;
    } catch (error) {
      setLoadingAuth(true);
      console.log("Erro ao registrar devolução:", error);
      return null;
    }
  }

  async function registrarDevolucao(pfc, motivo, cliente, lote) {
    // Obtém a referência da coleção de devoluções
    const devolucoesRef = firebase.firestore().collection("devolucoes");

    // Cria um novo documento na coleção de devoluções
    const devolucaoDoc = await devolucoesRef.add({
      pfc: pfc,
      cliente: cliente,
      lote: lote,
      dataDevolucao: firebase.firestore.FieldValue.serverTimestamp(),
      motivo: motivo,
      contador: 1, // Inicializa o contador com 1
    });

    // Incrementa o contador na coleção de produtos
    const produtoRef = firebase
      .firestore()
      .collection("produto")
      .where("pfc", "==", pfc)
      .limit(1);
    const snapshot = await produtoRef.get();

    if (!snapshot.empty) {
      const docRef = snapshot.docs[0].ref;
      await docRef.update({
        contadorDevolucoes: firebase.firestore.FieldValue.increment(1),
      });
    }

    return devolucaoDoc.id; // Retorna o ID do documento de devolução criado
  }

  // DEVOLUÇÃO | EXPEDIÇÃO
  async function compareItensSub1WhereDevolucaoExpicao(
    itemToCompare,
    clienteUser
  ) {
    setLoadingAuth(false);
    try {
      const itemsQuery = firebase.firestore().collectionGroup("loteProduto");
      let querySnapshot = await itemsQuery.get();
      if (!querySnapshot.empty) {
        const promises = [];
        let updated = false; // variável de controle
        querySnapshot.forEach((doc) => {
          const produtos = doc.data().produtos;
          const lote = doc.data().lote;
          const cliente = doc.data().cliente;
          if (produtos) {
            const produtosAtualizados = produtos.map((produto) => {
              if (
                produto.pfc === itemToCompare &&
                produto.status !== "devolvido"
              ) {
                produto.status = "devolvido";
                // executa registrarDevolucao apenas na primeira atualização de status
                if (!updated) {
                  updated = true;
                  registrarDevolucaoExpedicao(
                    itemToCompare,
                    "Devolução do produto",
                    clienteUser,
                    cliente,
                    lote
                  );
                }
              }
              return produto;
            });
            const loteRef = doc.ref.parent;
            const loteId = doc.id;
            // Atualiza a coleção "loteProduto" com os produtos atualizados
            const promise = loteRef
              .doc(loteId)
              .update({
                produtos: produtosAtualizados,
              })
              .then(() => true);
            promises.push(promise);
          }
        });
        const results = await Promise.all(promises);
        setLoadingAuth(true);
        return updated ? true : false; // retorna true apenas se houve atualização
      }
      setLoadingAuth(true);
      return false;
    } catch (error) {
      setLoadingAuth(true);
      console.log("Erro ao registrar devolução:", error);
      return null;
    }
  }

  async function registrarDevolucaoExpedicao(
    pfc,
    motivo,
    clienteUser,
    cliente,
    lote
  ) {
    // Obtém a referência da coleção de devoluções
    const devolucoesRef = firebase.firestore().collection("devolucoes");

    // Cria um novo documento na coleção de devoluções
    const devolucaoDoc = await devolucoesRef.add({
      pfc: pfc,
      clinteDevolucao: clienteUser,
      cliente: cliente,
      lote: lote,
      dataDevolucao: firebase.firestore.FieldValue.serverTimestamp(),
      motivo: motivo,
      contador: 1, // Inicializa o contador com 1
    });

    // Incrementa o contador na coleção de produtos
    const produtoRef = firebase
      .firestore()
      .collection("produto")
      .where("pfc", "==", pfc)
      .limit(1);
    const snapshot = await produtoRef.get();

    if (!snapshot.empty) {
      const docRef = snapshot.docs[0].ref;
      await docRef.update({
        contadorDevolucoes: firebase.firestore.FieldValue.increment(1),
      });
    }

    return true; // Retorna o ID do documento de devolução criado
  }

  // FIM DEVOLUÇÃO | EXPEDIÇÃO
  async function registrarRejeitado(id, pfc, motivo) {
    // Obtém a referência da coleção de devoluções
    const devolucoesRef = firebase.firestore().collection("rejeitados");

    // Cria um novo documento na coleção de devoluções
    const devolucaoDoc = await devolucoesRef.add({
      pfc: pfc,
      dataRejeitado: firebase.firestore.FieldValue.serverTimestamp(),
      motivo: motivo,
      contador: 1, // Inicializa o contador com 1
    });

    // Incrementa o contador na coleção de produtos
    const produtoRef = firebase
      .firestore()
      .collection("produto")
      .where("pfc", "==", pfc)
      .limit(1);
    const snapshot = await produtoRef.get();

    if (!snapshot.empty) {
      const docRef = snapshot.docs[0].ref;
      await docRef.delete();
    }

    return devolucaoDoc.id; // Retorna o ID do documento de devolução criado
  }

  async function deletarWhere(item, itemCompare, to, itemToCompare) {
    setLoadingAuth(false);
    await firebase
      .firestore()
      .collection(item)
      .where(itemCompare, to, itemToCompare)
      .get()
      .then((querySnapshot) => {
        const promises = [];

        querySnapshot.forEach((doc) => {
          // Deleta o documento
          const promise = doc.ref.delete();
          promises.push(promise);
        });

        // Espera por todas as promises serem resolvidas
        return Promise.all(promises);
      })
      .then(() => {
        setLoadingAuth(true);
      })
      .catch((error) => {
        console.log(error);
        setLoadingAuth(true);
      });
  }

  // async function buscarDadosPeriodo(clienteId, dataInicio, dataFim) {
  //   const loteProdutoRef = firebase.firestore().collection("loteProduto");
  //   const devolucoesRef = firebase.firestore().collection("devolucoes");
  //   const clientesRef = firebase.firestore().collection("clientes");

  //   const loteProdutoQuery = loteProdutoRef
  //     .where("cliente", "==", clienteId)
  //     .where("created", ">=", dataInicio)
  //     .where("created", "<=", dataFim);
  //   const loteProdutoSnapshot = await loteProdutoQuery.get();

  //   let produtos = [];
  //   let quantidadeLote = 0;
  //   let quantidadeProdutos = 0;

  //   loteProdutoSnapshot.docs.forEach((loteProdutoDoc) => {
  //     const loteProduto = loteProdutoDoc.data();
  //     produtos.push(loteProduto.produtos);
  //     quantidadeProdutos += loteProduto.produtos.length;
  //     quantidadeLote++;
  //   });

  //   const devolucoesQuery = devolucoesRef
  //     .where("cliente", "==", clienteId)
  //     .where("dataDevolucao", ">=", dataInicio)
  //     .where("dataDevolucao", "<=", dataFim);
  //   const devolucoesSnapshot = await devolucoesQuery.get();

  //   let devolucoesCliente = [];
  //   let devolucoesClienteDevolucao = [];
  //   let devolucoesClienteNome = [];

  //   let quantidadeDevolucoesCliente = 0;
  //   let quantidadeDevolucoesClienteDevolucao = 0;

  //   devolucoesSnapshot.docs.forEach((devolucaoDoc) => {
  //     const devolucao = devolucaoDoc.data();
  //     const { cliente, clinteDevolucao } = devolucao;

  //     if (cliente === clienteId) {
  //       quantidadeDevolucoesCliente++;
  //       devolucoesCliente.push(devolucao);
  //     }

  //     if (clinteDevolucao !== clienteId) {
  //       quantidadeDevolucoesClienteDevolucao++;
  //       devolucoesClienteNome.push(devolucao.clinteDevolucao);
  //       devolucoesClienteDevolucao.push(devolucao);
  //     }
  //   });

  //   const clienteDoc = await clientesRef.doc(clienteId).get();
  //   const cliente = clienteDoc.exists ? clienteDoc.data() : null;

  //   return {
  //     produtosQuantidade: quantidadeProdutos,
  //     devolucoesClienteQuantidade: quantidadeDevolucoesCliente,
  //     devolucoesClienteDevolucaoQuantidade:
  //       quantidadeDevolucoesClienteDevolucao,
  //     cliente: cliente,
  //     clienteDevolucao: devolucoesClienteNome,
  //     produtos: produtos,
  //     devolucoesCliente: devolucoesCliente,
  //     devolucoesClienteDevolucao: devolucoesClienteDevolucao,
  //     lote: quantidadeLote,
  //   };
  // }

  async function comparacaoProdutosAssociadosEmLote(itemToCompare) {
    setLoadingAuth(false);
    try {
      const itemsQuery = firebase.firestore().collectionGroup("loteProduto");
      const promises = [];
      let querySnapshot = await itemsQuery.get();
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const produtos = doc.data().produtos;
          if (produtos) {
            const filteredProdutos = produtos.filter(
              (produto) =>
                produto.pfc === itemToCompare && produto.status === "devolvido"
            );
            const filteredProdutos2 = produtos.filter(
              (produto) => produto.pfc === itemToCompare
            );
            if (filteredProdutos.length > 0 || filteredProdutos2.length === 0) {
              promises.push(true);
            }
          }
        });
        const results = await Promise.all(promises);
        setLoadingAuth(true);
        return results.includes(true);
      }

      setLoadingAuth(true);
      return promises;
    } catch (error) {
      setLoadingAuth(true);
      console.log("Erro ao registrar devolução:", error);
      return false;
    }
  }

  async function getRankingClientesDevolucoes() {
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     const clientesRef = firebase.firestore().collection("clientes");
    //     const clientesSnapshot = await clientesRef.get();

    //     const hoje = new Date();
    //     const ultimaSemana = new Date(hoje.getTime() - 7 * 24 * 60 * 60 * 1000);

    //     const ranking = [];

    //     for (const clienteDoc of clientesSnapshot.docs) {
    //       const cliente = { id: clienteDoc.id, ...clienteDoc.data() };
    //       const devolucoesRef = firebase
    //         .firestore()
    //         .collectionGroup("devolucoes")
    //         .where("cliente", "==", cliente.id)
    //         .where("dataDevolucao", ">=", ultimaSemana)
    //         .where("dataDevolucao", "<=", hoje);

    //       const devolucoesSnapshot = await devolucoesRef.get();

    //       const totalDevolucoes = devolucoesSnapshot.size;
    //       ranking.push({
    //         cliente: cliente.nome,
    //         totalDevolucoes: totalDevolucoes,
    //         variacao: 0,
    //         ranking: ultimaSemana
    //       });
    //     }

    //     ranking.sort((a, b) => b.totalDevolucoes - a.totalDevolucoes);

    //     let variacao = 0;
    //     let posicaoAnterior = 0;

    //     ranking.forEach((item, index) => {
    //       if (index > 0) {
    //         variacao = posicaoAnterior - index;
    //       }
    //       item.variacao = variacao;
    //       posicaoAnterior = index;
    //     });

    //     const rankingComVariacao = ranking.map((item) => {
    //       const seta = item.variacao > 0 ? "↑" : item.variacao === 0 ? "≡" : "↓";
    //       return {
    //         cliente: item.cliente,
    //         totalDevolucoes: item.totalDevolucoes,
    //         variacao: item.variacao,
    //         ranking: item.ranking,
    //         seta: seta,
    //       };
    //     });

    //     resolve(rankingComVariacao);
    //   } catch (error) {
    //     reject(error);
    //   }
    // });
    return new Promise(async (resolve, reject) => {
      try {
        const clientesRef = firebase.firestore().collection("clientes");
        const clientesSnapshot = await clientesRef.get();

        const ranking = [];

        for (const clienteDoc of clientesSnapshot.docs) {
          const cliente = { id: clienteDoc.id, ...clienteDoc.data() };
          const devolucoesRef = firebase
            .firestore()
            .collection("devolucoes")
            .where("cliente", "==", cliente.id);
          const devolucoesSnapshot = await devolucoesRef.get();

          const devolucoes = [];
          for (const devolucaoDoc of devolucoesSnapshot.docs) {
            const devolucao = { id: devolucaoDoc.id, ...devolucaoDoc.data() };
            const loteProdutosRef = await firebase
              .firestore()
              .collection("loteProdutos")
              .where("devolucao", "==", devolucao.id)
              .get();
            const loteProdutos = loteProdutosRef.docs.map((loteProdutoDoc) =>
              loteProdutoDoc.data()
            );
            devolucao.loteProdutos = loteProdutos;
            devolucoes.push(devolucao);
          }

          ranking.push({
            cliente: cliente.nome,
            devolucoes: devolucoes,
          });
        }

        resolve(ranking);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function getQuantidadeProdutosPorCliente() {
    return new Promise(async (resolve, reject) => {
      try {
        const clientesRef = firebase.firestore().collection("clientes");
        const clientesSnapshot = await clientesRef.get();

        const quantidadeProdutosPorCliente = [];

        for (const clienteDoc of clientesSnapshot.docs) {
          const cliente = { id: clienteDoc.id, ...clienteDoc.data() };
          const loteProdutoRef = firebase
            .firestore()
            .collection("loteProduto")
            .where("cliente", "==", cliente.id)
            .where("status", "!=", "devolvido");

          const loteProdutoSnapshot = await loteProdutoRef.get();

          const quantidadeProdutos = loteProdutoSnapshot.size;
          quantidadeProdutosPorCliente.push({
            cliente: cliente.nome,
            quantidadeProdutos: quantidadeProdutos,
          });
        }

        resolve(quantidadeProdutosPorCliente);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function getRankingClientesDevolucoesEQuantidadeProdutos() {
    try {
      const clientesRef = firebase.firestore().collection("distribuidor");
      const clientesSnapshot = await clientesRef.get();

      const rankingPromises = clientesSnapshot.docs.map(async (clienteDoc) => {
        const cliente = { id: clienteDoc.id, ...clienteDoc.data() };

        const devolucoesRef = firebase
          .firestore()
          .collectionGroup("produto")
          .where("distribuidorAtual", "==", cliente.id);

        const envaseRef = firebase
          .firestore()
          .collection("envase")
          .where("distribuidorAtual", "==", cliente.id);

        const [devolucoesSnapshot, envaseSnapshot] = await Promise.all([
          devolucoesRef.get(),
          envaseRef.get(),
        ]);

        const comodato = devolucoesSnapshot.size;
        const envase = envaseSnapshot.size;

        // Calcular a porcentagem de envase por comodato
        let envasePorComodato = comodato !== 0 ? (envase / comodato) * 100 : 0;

        return {
          nome: cliente.nome,
          comodato: comodato,
          envase: envase,
          porcentagem: envasePorComodato.toFixed(2), // Limitando para 2 casas decimais
        };
      });

      const ranking = await Promise.all(rankingPromises);

      // Ordenando a lista de clientes por número de comodatos (do maior para o menor)
      const sortedRanking = ranking.sort((a, b) => b.comodato - a.comodato);

      return sortedRanking;

    } catch (error) {
      return [];
    }
  }




  return (
    <AuthContextBd.Provider
      value={{
        loadItem,
        dadosLoad,
        loadingAuth,
        getDataForChart,
        getDataForChartEnvase,
        getDevolucoesQuantidade,
        getDevolucoesQuantidadeData,
        getEnvasamentosPorProduto,
        registrarAtualizacaoArquivo,
        registrarAtualizacao,
        compareItensSub2Where,
        compareItensSub1Where,
        compareItensSub1WhereDoc,
        compareItensSub1WhereDocRemessa,
        compareItensSub2WhereProduto,
        compareItensSub1WhereProduto,
        comparacaoProdutosAssociadosEmLote,
        compareItensSub1Doc,
        compareItensSub1DocRemessa,
        compareItensSub1WhereDevolucao,
        compareItensSub1WhereDevolucaoExpicao,
        compareItensSub1DocDashboard,
        getQuantidadeProdutosPorCliente,
        getRankingClientesDevolucoesEQuantidadeProdutos,
        loadCollection,
        loadCollectionSize,
        getRankingClientesDevolucoes,
        registrarRejeitado,
        buscarDadosPeriodo,
        deletarWhere,
        handleExc,
        compareItem,
        compareItens,
        handleAdd,
        handleAddLog,
        handleAddNormalizacao,
        handleAddLote
      }}
    >
      {children}
    </AuthContextBd.Provider>
  );
}

export default AuthProvider;
