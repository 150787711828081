export const tableColumnsTopCreators = [
  {
    Header: "NOME",
    accessor: "NOME",
  },
  {
    Header: "GI",
    accessor: "GI",
  },
];
