// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue, BeatLoader } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React from "react";

export default function Conversion(props) {
  const { piechartdata, piechartoptions, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          Meta envase
        </Text>
        <Select
          fontSize='sm'
          variant='subtle'
          defaultValue='monthly'
          width='unset'
          fontWeight='700'>
          {/* <option value='daily'>Dia</option> */}
          <option value='monthly'>Mês</option>
          {/* <option value='yearly'>Ano</option> */}
        </Select>
      </Flex>
      {piechartdata.length === 0 ?
        <PieChart
          key={Math.random()}
          h='100%'
          w='100%'
          chartData={pieChartData}
          chartOptions={pieChartOptions}
        />
        :
        <PieChart
          key={Math.random()}
          h='100%'
          w='100%'
          chartData={piechartdata}
          chartOptions={piechartoptions}
        />
      }
      <Card
        bg={cardColor}
        flexDirection={{ base: 'column', md: 'row' }} // 'column' em dispositivos móveis e 'row' em dispositivos médios e maiores
        boxShadow={cardShadow}
        align='center'
        w='100%'
        p='15px'
        px='20px'
        mt='15px'
        mx='auto'>
        <Flex direction='column' py='5px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#4318FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              +3x
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {piechartdata.length > 1 ? piechartdata[3] : "..."}
          </Text>
        </Flex>
        <VSeparator display={{ base: "none", md: "block" }} mx={{ md: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#3fa8d5' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              2 x
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {piechartdata.length > 1 ? piechartdata[2] : "..."}
          </Text>
        </Flex>
        <VSeparator display={{ base: "none", md: "block" }} mx={{ md: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#80b0f2' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              1 x
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {piechartdata.length > 1 ? piechartdata[1] : "..."}
          </Text>
        </Flex>
        <VSeparator display={{ base: "none", md: "block" }} mx={{ md: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#EFF4FB' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              0 x
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {piechartdata.length > 1 ? piechartdata[0] : "..."}
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
