import React, { useState, useEffect } from "react";

// Chakra imports
import {
  Box,
  Button, Flex,
  FormControl,
  FormLabel,
  Select,
  StackDivider,
  Text,
  VStack
} from "@chakra-ui/react";

import {
  MdLeakAdd,
  MdLeakRemove,
  MdSave
} from "react-icons/md";
// Assets

export default function Banner(props) {
  const { clientes, escolhaClientes, startFetching, stopFetching, fetchDataGetUser, fetching } = props;
  const [escolhaClientesChildren, setEscolhaClientesChildren] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canOpen, setCanOpen] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (escolhaClientesChildren) {
      escolhaClientes(escolhaClientesChildren);
      setCanOpen(true);
    } else {
      setCanOpen(false);
    }
  }, [escolhaClientesChildren]);

  const handleOpenConnection = () => {
    setIsLoading(true);
    startFetching();
    setCanClose(true);
  };

  const handleCloseConnection = () => {
    setIsLoading(false);
    stopFetching();
    setCanClose(false);
    setCanOpen(false);
    setTimeout(() => {
      setCanSave(true);
    }, 8000);

  };

  useEffect(() => {
    console.log(fetching)
    if (fetching) {
      setIsLoading(false);
      setCanOpen(false);
      setCanClose(false);
      setCanSave(false);
    }
  }, [fetching]);

  // reset save button to disabled whenever 'escolhaClientesChildren' changes
  useEffect(() => {
    setCanSave(false);
  }, [escolhaClientesChildren]);


  // Chakra Color Mode
  return (
    <Flex
      direction='column'
      bgGradient='linear(to-r, blue.500, blue.600)'
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "46%",
          xl: "70%",
          "2xl": "50%",
          "3xl": "42%",
        }}
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Colete e associe ao distribuidor
      </Text>
      <Text
        fontSize='md'
        color='#E3DAFF'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight='500'
        mb='40px'
        lineHeight='28px'>
        Etapas: {<br />}
        1) Escolha o distribuidor; {<br />}
        2) Abra conexão; {<br />}
        3) Feche conexão; e  {<br />}
        4) Salve as BlueTags.
      </Text>
      <VStack
        divider={<StackDivider borderColor='blue.200' />}
        spacing={4}
        align='stretch'
      >
        <Box w='100%'>
          <FormControl isRequired>
            <FormLabel>Etapa 1</FormLabel>
            <Select
              multiple={false}
              placeholder='Escolha o distribuidor'
              bg='white'
              color='black'
              _hover={{ bg: "whiteAlpha.900" }}
              _active={{ bg: "white" }}
              _focus={{ bg: "white" }}
              fontWeight='500'
              fontSize='14px'
              me='38px'
              value={escolhaClientesChildren}
              onChange={(e) => setEscolhaClientesChildren(e.target.value)}
            >
              {clientes.map((cliente, index) => (
                <option key={index} value={cliente.value}>{cliente.label}</option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box >
          <FormControl isRequired>
            <FormLabel>Etapa 2</FormLabel>
            <Flex
              flexDirection="row"
              gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
            >
              <Button
                onClick={handleOpenConnection}
                isLoading={isLoading && canOpen}
                isDisabled={!canOpen}
                leftIcon={<MdLeakAdd />}
                w="100%"
                bg='teal.200'
                color='black'
                _hover={{ bg: 'teal.400' }}
                _active={{ bg: 'teal.400' }}
                _focus={{ bg: 'teal.400' }}
                fontWeight='500'
                fontSize='14px'
                py='20px'
                me='5px'>
                Abrir Conexão
              </Button>
            </Flex>
          </FormControl>
        </Box>
        <Box w='100%'>
          <FormControl isRequired>
            <FormLabel>Etapa 3</FormLabel>
            <Flex
              flexDirection="row"
              gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
            >
              <Button
                onClick={handleCloseConnection}
                isLoading={!isLoading && canClose}
                isDisabled={!canClose}
                leftIcon={<MdLeakRemove />}
                w="100%"
                bg='red.200'
                color='black'
                _hover={{ bg: 'red.400' }}
                _active={{ bg: 'red.400' }}
                _focus={{ bg: 'red.400' }}
                fontWeight='500'
                fontSize='14px'
                py='20px'
                me='5px'>
                Fechar Conexão
              </Button>
            </Flex>
          </FormControl>
        </Box>
        <Box w='100%'>
          <FormControl isRequired>
            <FormLabel>Etapa 4</FormLabel>
            <Button
              leftIcon={<MdSave />}
              onClick={fetchDataGetUser}
              // isLoading={isLoading && canSave}
              isDisabled={!canSave}
              bg='white'
              color='black'
              _hover={{ bg: 'green.400', color: 'white' }}
              _active={{ bg: 'green.400', color: 'white' }}
              _focus={{ bg: 'green.400', color: 'white' }}
              fontWeight='500'
              fontSize='14px'
              py='20px'
              w="100%"
              me='5px'>
              Salvar BlueTags
            </Button>
          </FormControl>
        </Box>
      </VStack>
    </Flex>
  );
}
