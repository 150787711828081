
import { createContext, useEffect, useState, } from 'react';
import { toast } from 'react-toastify';
import firebase from '../services/firebaseConnection';

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    function loadStorage() {
      const storageUser = localStorage.getItem('SistemaUser');
      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }
      setLoading(false);
    }
    loadStorage();
  }, [])


  //Fazendo login do usuario
  async function signIn(email, password, navigate, location) {
    setLoadingAuth(true);
    await firebase.auth().signInWithEmailAndPassword(email, password,)
      .then(async (value) => {
        let uid = value.user.uid;
        // const token = await Object.entries(value.user)[1][1].user.accessToken
        const userProfile = await firebase.firestore().collection('users')
          .doc(uid).get();
        let data = {
          uid: uid,
          nome: userProfile.data().nome,
          email: value.user.email,
        };
        setUser(data);
        storageUser(data);
        await firebase.firestore().collection("log").add({
          nome: userProfile.data().nome,
          uid: uid,
          created: new Date(),
          tipo: "login",
        });
        navigate(location?.state?.from || `/admin/dashboard`)
        setLoadingAuth(false);
        toast.success('Bem vindo, ' + data.nome);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Ops algo deu errado!');
        setLoadingAuth(false);
      })
  }

  //Recuperando a senha
  async function RecoveredPassword(email) {
    setLoadingAuth(true);
    await firebase.auth().sendPasswordResetEmail(email)
      .then(function () {
        toast.success('Senha enviada com sucesso');
      })
      .catch(function (error) {
        console.log(error);
        toast.error('Ops algo deu errado!');
        setLoadingAuth(false);
      });
  }

  // Mudando a senha
  function reauthenticate(currentPassword) {
    let user = firebase.auth().currentUser;
    let cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  function changePassword(currentPassword, newPassword) {
    reauthenticate(currentPassword).then(() => {
      let user = firebase.auth().currentUser;
      user.updatePassword(newPassword).then(() => {
        toast.success('Senha atualizada com sucesso');
      }).catch((error) => { console.log(error); });
    }).catch((error) => { console.log(error); toast.error('A senha atual está errada!'); });
  }

  //Cadastrando um novo usuario
  async function signUp(email, password, nome) {
    setLoadingAuth(true);
    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        let uid = value.user.uid;
        await firebase.firestore().collection('users')
          .doc(uid).set({
            nome: nome,
            email: email,
            avatarUrl: null,
            permissions: "view-users",
          })
          .then(() => {
            let data = {
              uid: uid,
              nome: nome,
              email: value.user.email,
              avatarUrl: null,
              permissions: "view-users"
            };
            setUser(data);
            storageUser(data);
            setLoadingAuth(false);
            toast.success('Bem vindo a plataforma!');

          })
      })
      .catch((error) => {
        console.log(error);
        toast.error('Ops algo deu errado!');
        setLoadingAuth(false);
      })

  }

  function storageUser(data) {
    localStorage.setItem('SistemaUser', JSON.stringify(data));
  }

  //Logout do usuario
  async function signOut() {
    await firebase.firestore().collection("log").add({
      uid: user.uid,
      nome: user.nome,
      created: new Date(),
      tipo: "logout",
    });
    await firebase.auth().signOut();
    localStorage.removeItem('SistemaUser');
    setUser(null);
  }


  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signUp,
        signOut,
        signIn,
        RecoveredPassword,
        reauthenticate,
        changePassword,
        loadingAuth,
        setUser,
        storageUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;
