// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdCopyright,
  MdOutlineGrain,
  MdOutlineHighlightOff,
  MdPeopleAlt,
  MdSafetyDivider,
  MdUpdate,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { columnsDataComplex } from "views/admin/default/variables/columnsData";

import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { AuthContextBd } from "../../../contexts/bdFire";

export default function UserReports() {
  const {
    loadCollectionSize,
    compareItem,
    getDataForChart,
    getDevolucoesQuantidade,
    getDevolucoesQuantidadeData,
    getEnvasamentosPorProduto,
    getRankingClientesDevolucoesEQuantidadeProdutos,
    getDataForChartEnvase,
  } = React.useContext(AuthContextBd);
  const [clientes, setClientes] = React.useState([]);
  const [estoque, setEstoque] = React.useState([]);
  const [produtoEnvassado, setProdutosEnvassado] = React.useState([]);
  const [produto, setProduto] = React.useState([]);
  const [lote, setLote] = React.useState([]);
  const [devolucoes, setDevelocoes] = React.useState([]);
  const [rejeitados, setRejeitados] = React.useState([]);
  const [ranking, setRanking] = React.useState([]);

  const [dataDaily, setDataDaily] = React.useState([]);
  const [categoryDaily, setCategoryDaily] = React.useState([]);

  const [pieChartData, setPieChartData] = React.useState([]);
  const [pieChartLabels, setPieChartLabels] = React.useState([]);

  const [lineChartData, setLineChartData] = React.useState([]);
  const [lineChartLabels, setLineChartLabels] = React.useState([]);
  ////
  React.useEffect(() => {
    getDados();
    setRankClientePromise();
  }, []);

  React.useEffect(() => {
    const fetchAndPrepareData = async () => {
      const data = [];
      const categories = [];

      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);

        const count = await getDevolucoesQuantidadeData(date);
        data.push(count);

        const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}`;
        categories.push(formattedDate.toString());
      }

      return { data, categories };
    };
    fetchAndPrepareData().then(({ data, categories }) => {
      setDataDaily([
        {
          name: "Envase",
          data: data,
        },
      ]);
      setCategoryDaily({
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
            },
          },
          theme: "dark",
        },
        xaxis: {
          categories: categories,
          show: false,
          labels: {
            show: true,
            style: {
              colors: "#A3AED0",
              fontSize: "14px",
              fontWeight: "500",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          color: "black",
          labels: {
            show: true,
            style: {
              colors: "#CBD5E0",
              fontSize: "14px",
            },
          },
        },
        grid: {
          show: false,
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            colorStops: [
              [
                {
                  offset: 0,
                  color: "#4318FF",
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: "rgba(67, 24, 255, 1)",
                  opacity: 0.28,
                },
              ],
            ],
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "40px",
          },
        },
      });
    });
  }, []);

  React.useEffect(() => {
    const fetchAndPrepareData = async () => {
      // Fetch the data
      const productsByEnvaseCount = await getEnvasamentosPorProduto();

      if (!productsByEnvaseCount || typeof productsByEnvaseCount !== "object") {
        console.error(
          "productsByEnvaseCount is not an object:",
          productsByEnvaseCount
        );
        return;
      }

      // Prepare the data and labels for the Pie Chart
      const data = [];
      const labels = [];
      for (const [key, value] of Object.entries(productsByEnvaseCount)) {
        labels.push(key);
        data.push(value.length);
      }

      // Update the Pie Chart data and labels
      setPieChartData(data);
      setPieChartLabels(labels);
    };

    fetchAndPrepareData();
  }, []);

  React.useEffect(() => {
    function getLastSixMonths() {
      const now = new Date();
      const months = [];

      for (let i = 0; i < 6; i++) {
        const monthDate = new Date(now.getFullYear(), now.getMonth() - i);
        const monthName = new Intl.DateTimeFormat("pt-BR", {
          month: "short",
        }).format(monthDate);
        months.unshift(monthName.toUpperCase());
      }

      return months;
    }
    const lastSixMonths = getLastSixMonths();

    const fetchAndPrepareDataMes = async () => {
      // Fetch the data
      const chartDataEnvase = await getDataForChartEnvase();
      const chartData = await getDataForChart();

      // Prepare the data and labels for the Line Chart
      let data = [];
      let dataEnvase = [];
      const labels = [];

      chartData.forEach((entry) => {
        data.push(entry.count);
        labels.push(lastSixMonths[entry.month]);
      });
      chartDataEnvase.forEach((entry) => {
        dataEnvase.push(entry.count);
        labels.push(lastSixMonths[entry.month]);
      });
      // Reverse the data
      data = data.reverse();
      dataEnvase = dataEnvase.reverse();
      // Update the Line Chart data and labels
      setLineChartData([
        { name: "Comodato", data: data },
        { name: "Envase", data: dataEnvase },
      ]);
      setLineChartLabels({
        chart: {
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            top: 13,
            left: 0,
            blur: 10,
            opacity: 0.1,
            color: "#4318FF",
          },
        },
        colors: ["#4318FF", "#39B8FF"],
        markers: {
          size: 0,
          colors: "white",
          strokeColors: "#7551FF",
          strokeWidth: 3,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          showNullDataPoints: true,
        },
        tooltip: {
          theme: "dark",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          type: "line",
        },
        xaxis: {
          type: "numeric",
          categories: lastSixMonths,
          labels: {
            style: {
              colors: "#A3AED0",
              fontSize: "12px",
              fontWeight: "500",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
          column: {
            color: ["#7551FF", "#39B8FF"],
            opacity: 0.5,
          },
        },
        color: ["#7551FF", "#39B8FF"],
      });
    };

    fetchAndPrepareDataMes();
  }, []);

  // Update pieChartOptions with the new labels
  const pieChartOptions = {
    labels: pieChartLabels,
    colors: ["#EFF4FB", "#3fa8d5","#80b0f2", "#4318FF"],
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: ["#EFF4FB", "#80b0f2","#3fa8d5", "#4318FF"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  async function getDados() {
    try {
      const [
        comparacaoItem2,
        loadProdutos,
        loadComodato,
        loadRejeitados,
        loadLoteProdutos,
        loadEnvase,
      ] = await Promise.all([
        compareItem("distribuidor", "status", "==", true),
        loadCollectionSize("produto"),
        getDevolucoesQuantidade(),
        loadCollectionSize("rejeitado"),
        loadCollectionSize("lote"),
        loadCollectionSize("envase"),
      ]);

      setClientes((clientes) =>
        comparacaoItem2
          .map((item) => ({
            label: item.nome + ": " + item.identificador,
            value: item.id,
          }))
          .concat(clientes)
      );

      setProduto(loadProdutos);
      setLote(loadLoteProdutos);
      setRejeitados(loadRejeitados);
      setEstoque(loadProdutos - loadComodato);
      setDevelocoes(loadEnvase);
      setProdutosEnvassado(loadComodato);
    } catch (error) {
      console.error(error);
    }
  }

  const setRankClientePromise = async () => {
    await getRankingClientesDevolucoesEQuantidadeProdutos()
      .then((ranking) => {
        setRanking(ranking);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdUpdate} color={brandColor} />}
            />
          }
          name="Devoluções"
          value={devolucoes.toLocaleString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdSafetyDivider}
                  color={brandColor}
                />
              }
            />
          }
          name="Em comodato"
          value={produtoEnvassado.toLocaleString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineGrain}
                  color={brandColor}
                />
              }
            />
          }
          // growth="+23%"
          name="Estoque"
          value={estoque.toLocaleString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdCopyright} color={brandColor} />
              }
            />
          }
          // endContent={
          //   <Flex me="-16px" mt="10px">
          //     <FormLabel htmlFor="balance">
          //       <Avatar src={Usa} />
          //     </FormLabel>
          //     <Select
          //       id="balance"
          //       variant="mini"
          //       mt="5px"
          //       me="0px"
          //       defaultValue="usd"
          //     >
          //       <option value="usd">USD</option>
          //       <option value="eur">EUR</option>
          //       <option value="gba">GBA</option>
          //     </Select>
          //   </Flex>
          // }
          name="Garrafões Inteligentes"
          value={produto.toLocaleString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #eb5544 0%, #fe0404 100%)"
              icon={
                <Icon
                  w="28px"
                  h="28px"
                  as={MdOutlineHighlightOff}
                  color="white"
                />
              }
            />
          }
          name="Rejeitados"
          value={rejeitados.toLocaleString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPeopleAlt} color={brandColor} />
              }
            />
          }
          name="Distribuidores"
          value={clientes.length.toLocaleString()}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent
          envase={devolucoes}
          produto={produtoEnvassado}
          linechartdata={lineChartData}
          linechartlabels={lineChartLabels}
        />
        <WeeklyRevenue datadaily={dataDaily} categorydaily={categoryDaily} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        {/* <CheckTable ranking={ranking} /> */}
        <ComplexTable
          ranking={ranking}
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px"> */}
        {/* <DailyTraffic datadaily={dataDaily} categorydaily={categoryDaily} /> */}
        <PieCard
          piechartoptions={pieChartOptions}
          piechartdata={pieChartData}
        />
        {/* </SimpleGrid> */}
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          ranking={ranking}
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
