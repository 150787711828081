import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './contexts/auth';
import AuthProviderBd from './contexts/bdFire';
import Routes from 'routes';
import { Flip, ToastContainer } from 'react-toastify';
import '../src/services/firebaseOffline'


function App() {
  return (
    <AuthProvider>
      <AuthProviderBd>
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={600}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            transition={Flip}
            pauseOnHover
            theme="dark" />
          <Routes />
        </BrowserRouter>
      </AuthProviderBd>
    </AuthProvider>
  );
}

export default App;
