import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import avatar from "assets/img/avatars/avatarSimmmple.png";

function TopCreatorTable(props) {
  const { columnsData, tableData, blueTags } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const [data, setData] = React.useState(tableData);


  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            BluePortal
          </Text>
          <Button variant='action'>Ao vivo</Button>
        </Flex>
        <Table   w='100%'
          px='22px'
          pb='20px'
          mb='10px' variant='simple' color='gray.500'>
          <Thead>
            <Tr key={Math.random()}>
              <Th
                pe='10px'
                key={Math.random()}
                borderColor='transparent'>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                    BluePortal 
                </Flex>
              </Th>
              <Th
                pe='10px'
                key={Math.random()}
                borderColor='transparent'>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                    GI 
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody >
            <Tr key={Math.random()}>
              <Flex paddingLeft={5} align='center'>
                <Avatar
                  src={avatar}
                  w='30px'
                  h='30px'
                  me='8px'
                />
                <Text
                  color={textColor}
                  fontSize='sm'
                  fontWeight='600'>
                  Esteira 01
                </Text>
              </Flex>
              <Text
                color={textColorSecondary}
                fontSize='sm'
                fontWeight='500'>
              </Text>
              <Td
                key={Math.random()}
                fontSize={{ sm: "14px" }}
                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                borderColor='transparent'>
                {blueTags.length > 0 ? blueTags.length : "0"}
              </Td>
            </Tr>
          </Tbody>
        </Table>
        {/* <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NOME") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src={avatar}
                            w='30px'
                            h='30px'
                            me='8px'
                          />
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value[0]}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "GI") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table> */}
      </Flex>
    </>
  );
}

export default TopCreatorTable;
