
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdSnowing,
  MdOutlineDeblur,
  MdPerson
} from "react-icons/md";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  },
  // {
  //   name: "Garrafões",
  //   layout: "/admin",
  //   path: "/garrafoes",
  //   icon: (
  //     <Icon
  //       as={MdSnowing}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   secondary: true,
  // },
  {
    name: "BluePortal",
    layout: "/admin",
    path: "/blueportal",
    icon:
      <Icon
        as={MdOutlineDeblur}
        width='20px'
        height='20px'
        color='inherit'
      />,
      secondary: true,
  },
  // {
  //   name: "Blockchain",
  //   layout: "/admin",
  //   path: "/blockchain",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   secondary: true,
  // },
  // {
  //   name: "Perfil",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   secondary: true,
  // },

];

export default routes;
