export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "DISTRIBUIDOR",
    accessor: "nome",
  },
  {
    Header: "COMODATO",
    accessor: "comodato",
  },
  {
    Header: "ENVASE",
    accessor: "envase",
  },
  {
    Header: "PORCETAGEM",
    accessor: "porcentagem",
  },
];
