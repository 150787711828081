import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD5ZXwEYtK7mRI47DkORTixrCyoi6Wm_IU",
  authDomain: "cristal-tdc.firebaseapp.com",
  databaseURL: "https://cristal-tdc-default-rtdb.firebaseio.com",
  projectId: "cristal-tdc",
  storageBucket: "cristal-tdc.appspot.com",
  messagingSenderId: "187705711738",
  appId: "1:187705711738:web:a0934bbcc3f3b1cbe12fc3"
};

// servidor teste

// const firebaseConfig = {
//   apiKey: "AIzaSyDZVQMtFBGiBWObDWpHvOJkntDN3j9JrCE",
//   authDomain: "desenvolvimento-tdc.firebaseapp.com",
//   projectId: "desenvolvimento-tdc",
//   storageBucket: "desenvolvimento-tdc.appspot.com",
//   messagingSenderId: "605639229966",
//   appId: "1:605639229966:web:88cf5c00816926aedc725f"
// };


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;